<script setup>
import InputText from "@/components/forms/InputText.vue";
import SaleHeader from "@/components/global/SaleHeader.vue";
import ErrorBar from "@/components/global/ErrorBar.vue";
import VendorBlock from "@/components/sales/VendorBlock.vue";
import InputSelect from "@/components/forms/InputSelect.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {useApp} from "@/stores/app";
import useSignup from "@/composables/useSignup.js";
import {useRouter} from "vue-router";
import general from "@/composables/signup/modules/general.js";
import LeadsLoader from "@/components/global/LeadsLoader.vue";

const router = useRouter();
const app = useApp();
const {token, providers:all_providers, selectedProvider, place, user, addLocation, getPaymentDetails, createPaymentDetail, setPaymentDetail, setLocation, setOrderDetail} = useSignup()
const is_loading = ref(false)
const locale = ref()
const disabled = computed(() => {
    return !form.location.type ||
        (['complex', 'business', 'estate', 'apartment'].includes(form.location.type) && !form.location.buildingNumber) ||
        (['complex', 'business', 'estate', 'apartment', 'school'].includes(form.location.type) && !form.location.buildingName) ||
        !form.order.type ||
        (form.order.type === 'transfer' && (!form.order.previousProviderFSAN || !form.order.previousProviderEmail)) ||
        !form.order.phoneNumber || is_loading.value || !form.location.streetName || !form.location.streetNumber || !form.location.suburb || !form.location.city || !form.location.province || !form.location.postalCode
})
const location_types = ref([
				{
					name: 'Freestanding Home',
					id: 'house'
				},
				{
					name: 'Complex',
					id: 'complex'
				},
				{
					name: 'Business/Office Building',
					id: 'business'
				},
				{
					name: 'School Campus',
					id: 'school'
				},
				{
					name: 'Estate',
					id: 'estate'
				},
				{
					name: 'Apartment',
					id: 'apartment'
				}
			])
const order_types = ref([
				{
					name: 'New Installation',
					id: 'new'
				},
				{
					name: 'Migrate/Transfer my existing Fibre line',
					id: 'transfer',
				}
			])
const providers = ref([])
const form = reactive({
        location: {
            type: 'house',
            name: '',
            buildingNumber: '',
            buildingName: '',
            streetName: '',
            streetNumber: '',
            suburb: '',
            city: '',
            province: '',
            postalCode: '',
        },
        order: {
            type: "new",
            previousProvider: '',
            previousProviderEmail: '',
            previousProviderFSAN: '',
            previousProviderObjectNumber: '',
            phoneNumber: '',
            alternatePhoneNumber: '',
        },
        missingFields: [],
    })
const error = ref('')

const namify = (value) => {
        return value.replace('_', '').replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
const submit = async () => {
    error.value = ""

    //validate info
	if(!form.location.streetName) {
        error.value = 'Street name cannot be empty.';
        return;
    }
    if(!form.location.streetNumber) {
        error.value = 'Street number cannot be empty.';
        return;
    }
    if(!form.location.suburb) {
        error.value = 'Suburb cannot be empty.';
        return;
    }
    if(!form.location.city) {
        error.value = 'City cannot be empty.';
        return;
    }
    if(!form.location.province) {
        error.value = 'Province cannot be empty.';
        return;
    }
    if(!form.location.postalCode) {
        error.value = 'Postal code cannot be empty.';
        return;
    }
    if (!form.location.type) {
        error.value = 'Please select the type of location.';
        return;
    }
    if (['complex', 'business', 'estate', 'apartment'].indexOf(form.location.type) >= 0) {
        if (!form.location.buildingNumber) {
            error.value = {message: 'Unit number cannot be empty.'};
            return;
        }
    }
    if (['complex', 'business', 'estate', 'apartment', 'school'].indexOf(form.location.type) >= 0) {
        if (!form.location.buildingName) {
            if (['business', 'apartment'].indexOf(form.location.type) >= 0) {
                error.value = 'Building name cannot be empty.'
            } else {
                error.value = `${form.location.type.charAt(0).toUpperCase() + form.location.type.slice(1)} name cannot be empty.`;
            }
            return;
        }
    }
    if (!form.order.type) {
        error.value = 'Please choose whether you are migrating or ordering a new installation.';
        return;
    }
    if (form.order.type === 'transfer' &&
        (!form.order.previousProviderEmail || !form.order.previousProviderEmail.match(/^[\w\d._%+-]+@([\w\d.-]+\.)+[\w-]{2,}$/)) ) {
        error.value = `Enter a valid email address that was used to sign up to ${namify(form.order.previousProvider)}.`;
        return;
    }
    let phoneRegex = /[0](\d{9})|([0](\d{2})( |-)((\d{3}))( |-)(\d{4}))|[0](\d{2})( |-)(\d{7})/;
    if (!form.order.phoneNumber || !form.order.phoneNumber.match(phoneRegex)) {
        error.value = 'Enter a valid phone number.';
        return;
    }
    if (form.order.alternatePhoneNumber && !form.order.alternatePhoneNumber.match(phoneRegex)) {
        error.value = 'Enter a valid alternate contact number.';
        return;
    }
    if (form.order.phoneNumber === form.order.alternatePhoneNumber) {
        error.value = 'Please use a different alternative contact number from your contact number.';
        return;
    }

    is_loading.value = true
    await addLocation(form.location).then(response => {
        setLocation(response.location)
        setOrderDetail(form.order)

        getPaymentDetails().then(response => {
            const prepaid_payment = response.payment_details.find((payment) => payment.payment_method === 'PP')
            if (prepaid_payment) {
                setPaymentDetail(prepaid_payment)
				router.push('/sale/order/confirm')
				return
            }

			const payment_detail = {
				payment_method: 'PP'
			}
			createPaymentDetail(payment_detail).then(response => {
				setPaymentDetail(response.payment_detail)
				router.push('/sale/order/confirm')
			}).catch(e => {
				error.value = e.response.data.message ?? 'Ooops something went wrong'
				is_loading.value = false
			})


        }).catch(e => {
            error.value = e.response.data.message ?? 'Ooops something went wrong'
            is_loading.value = false
        })
    }).catch(e => {
        error.value = e.response.data.message ?? 'Ooops something went wrong'
        is_loading.value = false
    })
}

onMounted(async () => {
    app._showNav = false
    if(!token.value)
        router.back()

    form.order.phoneNumber = user.value.cell_number ?? ''
    locale.value = await general.mapsToLocation({place: place.value, coordinates: {lat: place.value.geometry.location.lat(), lng: place.value.geometry.location.lng()} })

    if(locale.value) {
        form.location.streetName = locale.value.streetName || '';
        form.location.streetNumber = locale.value.streetNumber || '';
        form.location.suburb = locale.value.suburb || '';
        form.location.city = locale.value.city || '';
        form.location.province = locale.value.province || '';
        form.location.postalCode = locale.value.postalCode || '';
        form.location.latitude = locale.value.latitude.toFixed(6) || '';
        form.location.longitude = locale.value.longitude.toFixed(6) || '';
    }

    all_providers.value.forEach(p => {
        if(p.vendor.slug !== selectedProvider.value.vendor.slug)
            providers.value.push({name: p.vendor.name, id: p.vendor.slug})
    })

    form.order.previousProviderFSAN = providers.value[0]?.id
})
</script>

<template>
<sale-header title="Location details."/>
<main class="container --sale-lead-form-container --has-header">
    <vendor-block />
    <div class="package-block">
        <form class="package-form">
            <section>
                <div class="form-row">
                    <div class="form-columns">
                        <p class="form-heading">Location details.</p>
                        <input-select v-model="form.location.type" :options="location_types" label="Location type" :required="true"/>
						<input-text v-model="form.location.streetName" type="text" label="Street name" v-if="!locale.streetName" />
                        <input-text v-model="form.location.streetNumber" type="text" label="Street number" v-if="!locale.streetNumber" />
                        <input-text v-model="form.location.suburb" type="text" label="Suburb" v-if="!locale.suburb" />
                        <input-text v-model="form.location.city" type="text" label="City" v-if="!locale.city" />
                        <input-text v-model="form.location.province" type="text" label="Province" v-if="!locale.province" />
                        <input-text v-model="form.location.postalCode" type="text" label="Postal code" v-if="!locale.postalCode" />
                        <input-text v-model="form.location.buildingNumber" type="text" label="Unit number" v-if="['complex', 'business', 'estate', 'apartment'].includes(form.location.type)" :required="true"/>
                        <input-text v-model="form.location.buildingName" type="text" label="Complex name" v-if="form.location.type === 'complex'" :required="true"/>
                        <input-text v-model="form.location.buildingName" type="text" label="Building name" v-if="form.location.type === 'business'" :required="true"/>
                        <input-text v-model="form.location.buildingName" type="text" label="School name" v-if="form.location.type === 'school'" :required="true"/>
                        <input-text v-model="form.location.buildingName" type="text" label="Estate name" v-if="['estate', 'apartment'].includes(form.location.type)" :required="true"/>
                        <input-text v-model="form.location.name" type="text" label="Custom name (optional)" />
                    </div>
                    <div class="form-columns">
                        <p class="form-heading">Installation details.</p>
                        <input-select v-model="form.order.type" :options="order_types" label="Order type" :required="true"/>
                        <input-select v-if="form.order.type === 'transfer'" v-model="form.order.previousProviderFSAN" :options="providers" label="Existing Fibre provider" :required="true"/>
                        <input-text v-if="form.order.type === 'transfer'" v-model="form.order.previousProviderEmail" type="text" label="Email address used to sign up with" :required="true" />
                        <input-text v-model="form.order.phoneNumber" type="tel" label="Contact number for installation" :required="true" />
                        <input-text v-model="form.order.alternatePhoneNumber" type="tel" label="Alternative contact number" :required="false" />
                    </div>
                </div>
                 <error-bar :error />
            </section>



            <button class="button --primary" :class="{'--disabled':disabled}" :disabled @click.prevent="submit" type="submit">
                <leads-loader type="secondary" v-show="is_loading" />
                {{ !is_loading ? 'Continue' : '' }}
            </button>
        </form>
    </div>
</main>
</template>

<style scoped lang="scss">
.error-bar {
    margin-top: 4rem;
}

@include mobile() {
    .form-columns {
        margin-bottom: 4rem;

        &:first-child {
            margin-bottom: 2rem;
        }
    }
}
</style>
