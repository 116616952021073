<script setup lang="ts">
	import {computed, onMounted, onUnmounted, reactive, ref} from "vue";
	import {useRouter} from "vue-router";
	import InputText from "@/components/forms/InputText.vue";
	import useSignup from "@/composables/useSignup";
	const { setResellerDetails, getResellerDetails, reseller_details } = useSignup()

	const now = ref(new Date());
	const router = useRouter();
	const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let intervalId: any

	const resellerDetails = reactive({
		phone_number: null,
		code: null
	})

	const formatTime = (number: number) => {
		return number.toString().length === 1 ? `0${number.toString()}` : number
	}

	const next = () => {
		setResellerDetails(resellerDetails)
		router.push({
			name: 'sale-search'
		})
	}

	const hasResellerDetails = computed(() => {
		return (!!resellerDetails.phone_number && !!resellerDetails.code)
	})

	onMounted(() => {
		intervalId = setInterval(() => now.value = new Date(), 1000)
		getResellerDetails()
		// console.log(reseller_details.value)
		Object.assign(resellerDetails, {...reseller_details.value})
	})

	onUnmounted(() => {
		if(intervalId) clearInterval(intervalId)
	})
</script>

<template>
	<main class="container --background">
		<p class="dashboard-heading">{{ days[now.getDay() - 1] }}<br/>
			{{ now.getDate().toString().length === 2 ? now.getDate() : `0${now.getDate()}` }}
			{{ months[now.getUTCMonth()] }} {{ now.getFullYear() }}
			<span>
                {{ formatTime(now.getHours()) }}:{{ formatTime(now.getMinutes()) }}
            </span>
		</p>

		<div class="text-input-container">
			<input-text type="text" label="Reseller code" v-model="resellerDetails.code"/>
			<input-text type="text" label="Agent WhatsApp number" v-model="resellerDetails.phone_number"/>
		</div>

		<div class="dashboard-button-container">
			<button class="button --size-large --primary" @click.prevent="next" :disabled="!hasResellerDetails">
				<p>New sale</p>
				<ah-icon name="star-group" :size="57.6" color="white"/>
			</button>
		</div>

		<!--  TODO: Add Affiliate code stuff-->

	</main>
</template>
<style lang="scss" scoped>
.container {
	position: relative;
	flex-direction: column;

	.dashboard-heading {
		display: block;
		@include font(4.8, 4.8, bold);
		position: absolute;
		top: 4rem;
		left: 4rem;

		span {
			color: var(--hazy);
			display: block;
			@include font(3.2, 4.8, book)
		}
	}

	.text-input-container {
		margin-bottom: 4rem;
		width: 100%;
		max-width: 51rem;

		@include mobile() {
			max-width: 100%;
			padding: 0 1rem;
		}

		> * {
			width: 100%;

			+ * {
				margin-top: 1rem;
			}
		}
	}

	.dashboard-button-container {
		display: flex;
		flex-direction: row;
		gap: 3rem;

		.button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 5.76rem 0 5.52rem;

			p {
				color: white;
				@include font(3.336, 3.4, bold)
			}

			.icon {
				height: 5.76rem;
			}

			&:disabled {
				background: var(--mist);
				cursor: default;
			}
		}
	}

	@media (max-width: 1080px) {
		.dashboard-button-container {
			flex-direction: column;
		}
	}

	@include mobile() {
		flex-direction: column;
		.dashboard-heading {
			position: relative;
			top: 0;
			left: 0;
			text-align: center;
			margin-bottom: 4rem;
			width: 100%;
		}

		.dashboard-button-container {
			gap: 2.057rem;
			width: 100%;
			padding: 0 1rem;

			.button {
				padding: 0 3.947rem 0 3.783rem;
				width: 100%;
				height: 9.872rem;

				p {
					@include font(2.303, 2.3, bold)
				}

				.icon {
					height: 3.947rem;
					width: 3.947rem;
				}
			}
		}
	}
}
</style>