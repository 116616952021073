<script setup>
import {useApp} from "@/stores/app";
import {onMounted, ref} from "vue";
import useSignup from "@/composables/useSignup";
import {useRouter} from "vue-router";

const {success, order_detail, reset} = useSignup()
const app = useApp()
const router = useRouter()
const cart_id = ref(null)
const success_response = ref(false)

const goBack = () => {
    if (success.value) {
        router.replace('/')
    } else {
        router.back()
    }
}

onMounted(() => {
  app._showNav = false
  success_response.value = success.value
  cart_id.value = order_detail.value?.cart_id ?? null
  reset()
})
</script>

<template>
<main class="--background">
    <button @click="goBack" class="close-button">
        <span class="close-circle"></span>
        <ah-icon name="cross-default" color="white" :size="24"/>
    </button>
    <div class="content --large"  :class="success_response ? '--success' : '--failed'">
        <div class="heading-block">
            <h1>
                <span class="response-heading">{{ success_response ? 'Thanks!' : 'Oh no :(' }}</span>
                {{ success_response ?
                'Your Pure Fibre order has been placed.'
                :
                'Something went wrong.'
                }}
            </h1>
            <p v-if="!success_response">Your request didn’t go through.</p>
            <h1 v-if="cart_id">Your Order reference is: {{cart_id}}</h1>
        </div>

        <button class="button" :class="success_response ? '--primary' : '--secondary'" @click="goBack()">
            {{ success_response ? 'Done' : 'Try again' }}
        </button>
    </div>
</main>
</template>

<style scoped lang="scss">
.content {
    .heading-block {
        h1 {
            font-size: 3.6rem;

            .response-heading {
                display: block;
                font-size: 9.6rem;
                line-height: 9.6rem;
            }
        }
    }

    &.--success {
        .heading-block {
            h1 {
                .response-heading {
                    color: var(--frosting);
                }
            }
        }
    }

    &.--failed {
        .heading-block {
            h1 {
                .response-heading {
                    color: var(--orange);
                }
            }
        }
    }
}
.--background {
    @include mobile() {
        align-items: center;
    }
}
</style>
