<script setup>
import {useApp} from "@/stores/app";
import {useRouter} from "vue-router";
import {onBeforeMount} from "vue";
import useSignup from "@/composables/useSignup.js";

const {user} = useSignup()
const appStore = useApp();
const router = useRouter();
const goBack = () => {
    router.replace('/sale/login')
}

onBeforeMount(() => {
    appStore._showNav = false
})
</script>

<template>
<main class="--background">
    <button @click="goBack" class="close-button">
        <span class="close-circle"></span>
        <ah-icon name="cross-default" color="white" :size="24"/>
    </button>
    <div class="content --large">
        <span class="icon-block --primary">
            <ah-icon name="lock-locked" color="white" :size="30"/>
        </span>
        <div class="heading-block">
            <h1><span>Reset instructions</span> sent.</h1>
            <p>Please follow the instructions sent to {{user}}</p>
        </div>


        <button class="button --primary" @click="router.replace('/sale/login')">Back to log in</button>
    </div>
</main>
</template>

<style scoped lang="scss">
@include mobile() {
    .heading-block {
        p {
            padding: 0 2rem;
        }
    }
}
</style>
