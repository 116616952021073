import axios from 'axios'
axios.defaults.baseURL = import.meta.env.VITE_PHOENIX_API_URL
// axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true;

export default {

    async post(url, request) {
        return await axios.post(url, request)
    },

    async get(url, params = {}, noAuth = false) {
        return await axios.get(url, {params: params})
    },

    async delete(url, request = {}) {
        return await axios.delete(url, request)
    }
}
