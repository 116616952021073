<script setup>
import {useApp} from "@/stores/app";
import {computed, onBeforeMount, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import InputText from "@/components/forms/InputText.vue";
import ErrorBar from "@/components/global/ErrorBar.vue";
import useSignup from "@/composables/useSignup.js";
import LeadsLoader from "@/components/global/LeadsLoader.vue";
import general from "@/composables/signup/modules/general.js";

const {login, updateToken, me, setUser, solution, place, user} = useSignup()
const is_loading = ref(false)
const error = ref('')
const appStore = useApp();
const router = useRouter();
const route = useRoute()
const location = ref()
const goBack = () => {
    router.back()
}
const form = reactive({
    username: "",
    password: ""
})
const disabled = computed(() =>
    !form.username || !form.password
)

const submit = async () => {
    is_loading.value = true
    error.value = ''
    await login(form).then((response) => {
        updateToken(response.token)
        me().then(async res => {
            setUser(res.client_contact)
            location.value = await general.mapsToLocation({place: place.value, coordinates: {lat: place.value.geometry.location.lat(), lng: place.value.geometry.location.lng()} })
            await router.push('/sale/location-details')
        }).catch(e => {
            if(e.response.status === 511){
                setUser(e.response.data.otp_details.methods[0].destination)
                router.replace('/sale/otp')
            }
            error.value = e.response.data.message ?? 'Ooops something went wrong'
            is_loading.value = false
        })
    }).catch((e) => {
        error.value = e.response.data.message ?? 'Ooops something went wrong'
        is_loading.value = false
    })
}

onBeforeMount(() => {
    appStore._showNav = false

    if(!solution.value)
        router.replace('/sale/search')
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>
        <div class="content --large">
            <div class="heading-block">
                <h1><span>Log</span> in.</h1>
            </div>

            <div class="personal-information-header">
                    <p>Don’t have an account? <router-link :to="route.path.includes('lead') ? '/lead/create-account' : '/sale/create-account'">Create a new account</router-link></p>
            </div>

            <form @submit.prevent="submit">
                <div class="form-columns">
                    <input-text v-model="form.username" type="text" label="Username" :required="true" variant="secondary"/>
                    <input-text v-model="form.password" type="password" label="Password" :required="true" variant="secondary"/>
                </div>

                <error-bar :error />
                <button class="button --primary" :disabled="disabled || is_loading">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{!is_loading ? 'Log in' : ''}}
                </button>
                <button class="button --clear" @click.prevent="router.push(route.path.includes('lead') ? '/lead/reset-password' : '/sale/reset-password')" :disabled="is_loading">Reset password</button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">
.personal-information-header {
    width: 100%;
    border-radius: 2.4rem;
    background: rgba(255,255,255,.1);
    border: none;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        @include font(1.2,1.8,bold);
        color: rgba(255,255,255,.5);

        a {
            text-decoration: underline;
            color: rgba(255,255,255,.5);
        }
    }
}

.radio-group-row {
    margin-bottom: 0;
}
</style>
