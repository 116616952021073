<script setup>
import {useRoute} from "vue-router";
import useSignup from "../../composables/useSignup";
import {computed, onMounted, ref} from "vue";

const route = useRoute();
const {place, selectedProvider, solution} = useSignup()

defineProps({
    cart: {
        type: Boolean,
        default: false,
        required: false
    }
})

const name = computed(() => selectedProvider.value && route.name !== 'sale-providers' ? 'provider-' + selectedProvider.value.vendor.slug.replace(/_/g, '-') : 'product-pure-fibre')
const is_provider = ref(false)

onMounted(() => {
 is_provider.value = name.value.includes('provider-')
})
</script>

<template>
    <div class="vendor-block" :class="[{'provider-vendor-block':is_provider}, {'cart-container':cart}]">
        <ah-icon :name="name" :size="is_provider ? 170 : 236"/>
        <div v-if="!cart">
            <p class="formatted-address">{{ place.formatted_address }}</p>
            <router-link :to="'/sale/search'" class="edit-link">Edit address</router-link>
        </div>
        <div class="what-you-owe-block" v-else>
            <p>What you pay today <span>R{{solution.price}}.00</span></p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.vendor-block {
    width: 36rem;
    padding: 5rem 4rem;

    .formatted-address {
        @include font(1.6,2,bold);
        color: var(--thunder);
        margin-bottom: 2rem;
        margin-top: 4rem;
    }

    .edit-link {
        text-transform: uppercase;
        @include font(1.2,1.2,bold);
        color: var(--angelkiss);
    }

    .icon{
        display: block!important;
        height: 5rem;
        fill: var(--hazy);
    }

    &.provider-vendor-block {
        position: relative;

        .icon {
            position: absolute;
            left: 2rem;
            top: 0;
            display: inline-block!important;
            height: 17rem;
        }

        .formatted-address, .what-you-owe-block {
            margin-top: 9rem;
        }
    }

    .what-you-owe-block {
        margin-top: 4rem;
        padding: 2rem;
        border-radius: 3.2rem;
        background: var(--sweettooth);

        p {
            @include font(1.87,1.9,bold);
            color: white;

            span {
                display: block;
                @include font(2.933,2.9,bold);
                color: white;
                margin-top: 1rem;
            }
        }

        @media (max-width: 820px) {
            max-width: 52rem;

            p {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                span {
                    margin-top: 0;
                }
            }

        }

        @include mobile() {
            p {
                @include font(1.4,1.4,bold);
            }
        }
    }

    @include mobile() {
        .icon {
            fill: var(--hazy);
        }
    }
}

.cart-container {
    @include mobile() {
        padding: 4rem 1rem!important;

        .icon {
            left: 50%!important;
            transform: translateX(-50%);
        }
    }
}
</style>
