import './assets/scss/global/_all.scss'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import App from './App.vue'
import router from './router'
import {createAhIcon} from "@afrihost/icons";
import * as Sentry from "@sentry/vue"

const app = createApp(App)

app.use(createPinia())
.use(createAhIcon)
.use(VueGoogleMaps, {
		load: {
			key: import.meta.env.VITE_GOOGLE_MAPS_KEY,
			libraries: 'places',
			v: 3.53
		},
	})

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.DEV ? 'local' : 'live',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

app.use(router).mount("#app")
