<script setup>
import InputText from "@/components/forms/InputText.vue";
import ErrorBar from "@/components/global/ErrorBar.vue";
import {useApp} from "@/stores/app";
import {useRouter} from "vue-router";
import {computed, onBeforeMount, reactive, ref} from "vue";
import useSignup from "@/composables/useSignup.js";
import LeadsLoader from "@/components/global/LeadsLoader.vue";

const {resetPassword, setUser, solution} = useSignup()
const error = ref("")
const appStore = useApp();
const router = useRouter();
const goBack = () => {
    router.back()
}
const submit = async () => {
    is_loading.value = true
    await resetPassword(form.username).then(() => {
        setUser(form.username)
        router.push('/sale/reset-sent')
    }).catch(e => {
        console.log('error: ', e)
        error.value = "Oops something went wrong."
        is_loading.value = false
    })
}
const form = reactive({
    username: ""
})
const disabled = computed(() =>
    !form.username
)
const is_loading = ref(false)

onBeforeMount(() => {
    appStore._showNav = false
    if(!solution.value)
        router.replace('/sale/search')
})
</script>

<template>
    <main class="--background">
        <button @click="goBack" class="close-button">
            <span class="close-circle"></span>
            <ah-icon name="cross-default" color="white" :size="24"/>
        </button>
        <div class="content --large">
            <div class="heading-block">
                <h1><span>Reset</span> password.</h1>
                <p>Enter your ClientZone username below.</p>
            </div>

            <form @submit.prevent="submit">
                <input-text v-model="form.username" type="text" label="Username" :required="true" variant="secondary"/>
                <error-bar :error />
                <button class="button --primary" :disabled="disabled || is_loading" :class="{'--disabled':disabled}">
                    <leads-loader type="secondary" v-if="is_loading" />
                    {{!is_loading ? "Reset password" : ""}}
                </button>
            </form>
        </div>
    </main>
</template>

<style scoped lang="scss">
@include mobile() {
    .heading-block {
        p {
            padding: 0 2rem;
        }
    }
}
</style>
