import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ViewSaleSearch from '../views/sale/ViewSaleSearch.vue'
import ViewSaleProviders from '../views/sale/ViewSaleProviders.vue'
import ViewSalePackages from '../views/sale/ViewSalePackages.vue'
import ViewSaleCreateAccount from '../views/sale/ViewSaleCreateAccount.vue'
import ViewSaleLogin from '../views/sale/ViewSaleLogin.vue'
import ViewSaleOTP from '../views/sale/ViewSaleOTP.vue'
import ViewSaleResetPassword from '../views/sale/ViewSaleResetPassword.vue'
import ViewSaleResetSent from '../views/sale/ViewSaleResetSent.vue'
import ViewSaleLocationDetails from '../views/sale/ViewSaleLocationDetails.vue'
import ViewSaleConfirmOrder from '../views/sale/ViewSaleConfirmOrder.vue'
import ViewSaleResponse from '../views/sale/ViewSaleResponse.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{path: '/', name: 'home', component: HomeView, meta: {title: 'Home'}},
		{
			path: '/sale', name: 'sale', children: [
				{path: 'search', name: 'sale-search', component: ViewSaleSearch, meta: {title: 'Search'}},
				{path: 'providers', name: 'sale-providers', component: ViewSaleProviders, meta: {title: 'Providers'}},
				{path: 'packages', name: 'sale-packages', component: ViewSalePackages, meta: {title: 'Packages'}},
				{path: 'create-account', name: 'sale-create-account', component: ViewSaleCreateAccount, meta: {title: 'Create Account'}},
				{path: 'login', name: 'sale-login', component: ViewSaleLogin, meta: {title: 'Login'}},
				{path: 'otp', name: 'sale-otp', component: ViewSaleOTP, meta: {title: 'Verify OTP'}},
				{path: 'reset-password', name: 'sale-reset-password', component: ViewSaleResetPassword, meta: {title: 'Reset Password'}},
				{path: 'reset-sent', name: 'sale-reset-sent', component: ViewSaleResetSent, meta: {title: 'Reset Password Sent'}},
				{path: 'location-details', name: 'sale-location-details', component: ViewSaleLocationDetails, meta: {title: 'Location Details'}},
				{path: 'order/confirm', name: 'sale-order-confirm', component: ViewSaleConfirmOrder, meta: {title: 'Confirm Order'}},
				{path: 'order/response', name: 'sale-order-response', component: ViewSaleResponse, meta: {title: 'Order Response'}},
			]
		},
	]
})

router.beforeEach((to) => {
  const { title } = to.meta;
  const defaultTitle = 'Afrihost Reach Portal';
  document.title =  title ? `${defaultTitle} | ${title}` : defaultTitle
})

export default router
