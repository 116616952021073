<script setup>
	import {useApp} from "@/stores/app.ts";
	import SaleHeader from "@/components/global/SaleHeader.vue";
	import VendorBlock from "@/components/sales/VendorBlock.vue";
	import {onMounted, ref} from "vue";
	import LeadsToggle from "@/components/forms/LeadsToggle.vue";
	import useSignup from "@/composables/useSignup.js";
	import router from "@/router/index.ts";
	import LeadsLoader from "@/components/global/LeadsLoader.vue";
	import api from "@/services/api.js";
	import * as Sentry from "@sentry/vue"

	const {solution, createCart, location, setResponse, user, setOrderDetail, reseller_details, getResellerDetails} = useSignup()
	const app = useApp()
	const terms = ref(false)
	const is_loading = ref(false)
	const submit = async () => {
		is_loading.value = true
		await createCart().then(async (response) => {
			if (reseller_details.value.code && reseller_details.value.phone_number) {
				await api.post('/public/reseller-orders', {
					reseller_code: reseller_details.value.code,
					reseller_phone_number: reseller_details.value.phone_number,
					cart_id: response.cart.id
				})
			}
			setOrderDetail({"cart_id": response.cart.id})
			setResponse(true)
			await router.push('/sale/order/response')
		}).catch((e) => {
			Sentry.captureException(e)
			is_loading.value = false
			console.log(e)
			setResponse(false)
			router.push('/sale/order/response')
		})
	}

	onMounted(() => {
		if (!solution.value) {
			router.replace('/sale/packages')
		}
    getResellerDetails()
		app._showNav = false
	})
</script>

<template>
	<sale-header title="Confirm order."/>
	<main class="container --sale-lead-form-container --has-header" id="clear-bkg">
		<vendor-block :cart="true"/>
		<div class="package-block">
			<div class="cart-item-container">
				<div class="cart-item-row">
					<div class="cart-item">
						<div class="description-block">
							<ah-icon name="bolt" :size="30"/>
							<div class="descriptions">
								<p class="title">{{ solution.display_name }}</p>
							</div>
						</div>
						<div class="value-block">
							<p class="title --red">R{{ solution.price }}.00 pm</p>
						</div>
					</div>

					<div class="cart-item">
						<div class="description-block">
							<div class="descriptions">
								<p class="description --padded">Installation</p>
							</div>
						</div>

						<div class="value-block">
							<p class="description --green">Included</p>
						</div>
					</div>

					<div class="cart-item">
						<div class="description-block">
							<div class="descriptions">
								<p class="description --padded">Activation</p>
							</div>
						</div>

						<div class="value-block">
							<p class="description --green">Included</p>
						</div>
					</div>
				</div>
				<div class="cart-item-row">
					<div class="cart-item">
						<div class="description-block">
							<ah-icon name="wallet" :size="30"/>
							<div class="descriptions">
								<p class="title">Payment method</p>
								<p class="description">EasyPay</p>
							</div>
						</div>
					</div>
				</div>
				<div class="cart-item-row">
					<div class="cart-item">
						<div class="description-block">
							<ah-icon name="paper-summary" :size="30"/>
							<div class="descriptions">
								<p class="title">Terms and conditions</p>
							</div>
						</div>
						<div class="value-block">
							<leads-toggle v-model="terms"/>
						</div>
					</div>

					<div class="cart-item">
						<div class="description-block">
							<div class="descriptions">
								<p class="description --padded">By placing this order you accept all <a
									href="https://www.afrihost.com/terms-and-conditions" target="_blank">Terms and
									Conditions</a>.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="cart-item-row">
					<button class="button --primary" :class="{'--disabled':!terms || is_loading}"
							:disabled="!terms || is_loading" @click.prevent="submit">
						<leads-loader type="secondary" v-if="is_loading"/>
						{{ !is_loading ? "Place order" : "" }}
					</button>
				</div>
			</div>
		</div>
	</main>
</template>

<style lang="scss">
#clear-bkg {
	.package-block {
		background: transparent;
		padding: 0;
		border: solid var(--dew);
		border-width: .1rem 0 .1rem .1rem;
		overflow: hidden;

		.cart-item-container {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			height: 100%;

			.cart-item-row {
				padding: 2rem 6rem 2rem 4rem;
				background: white;
				min-height: 8.6rem;

				.cart-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 2rem;

					.description-block {
						display: flex;
						gap: 2rem;
						align-items: center;

						.icon {
							display: block !important;
							fill: var(--jellybean);
							height: 3rem;
						}

						.descriptions {
							.title {
								@include font(1.8, 2.2, bold);
								margin-bottom: 0;
							}

							.description {
								@include font(1.4, 1.4, book);
								margin-bottom: 2rem;

								&.--padded {
									padding-left: 5rem;
								}

								&:last-child {
									margin-bottom: 0;
								}

								a {
									color: var(--thunder);
									text-decoration: underline;
								}
							}

							p {
								&:nth-child(2) {
									margin-top: 1rem;
								}
							}
						}
					}

					.value-block {
						.title {
							@include font(2.2, 2.6, bold)
						}

						.description {
							@include font(1.4, 1.4, bold)
						}

						.--red {
							color: var(--plum);
						}

						.--green {
							color: var(--lime);
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				&:first-child {
					padding: 5.1rem 6rem 2rem 4rem;
				}

				&:last-child {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					flex: 1;
					padding: 2rem 6rem 4.5rem 4rem;

					@media (max-width: 820px) {
						align-items: center;
						justify-content: center;
					}
				}

				@include mobile() {
					padding: 2rem 2rem 2rem 1rem !important;

					.cart-item {
						.description-block {
							.descriptions {
								.title {
									@include font(1.4, 2.2, bold)
								}
							}
						}

						.value-block {
							.title {
								@include font(1.8, 1.8, bold);
								white-space: nowrap;
							}
						}
					}
				}
			}

			@include mobile() {
				height: auto;
			}
		}

		@include mobile() {
			background: url("@/assets/images/backgrounds/bl-mobile.svg") 2rem calc(100% - 2rem) no-repeat,
			url("@/assets/images/backgrounds/pure-fibre-mobile.svg") calc(100% - 2rem) calc(100% - 3.4rem) no-repeat,
			#FFFFFF;
			padding: 0 0 16.4rem;
			overflow: unset;

			.cart-item-container {
				background: #EFF2F5;
				border-radius: 2.8rem 2.8rem 0 0;
				overflow: hidden;

				.cart-item-row {
					.description-block {
						align-items: flex-start !important;

						.descriptions {
							max-width: 16.7rem;
						}
					}

					&:last-child {
						padding: 4rem 1rem 0 !important;
					}

					&:nth-child(3) {
						.descriptions {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
</style>
